import React, { useState, useEffect } from 'react';
import LNavbar from './lnavbar/Navbar';
import Background from './background/Background';
import LoadingBar from 'react-top-loading-bar';
import { useRef } from 'react';
import './About.css';

function About() {
    const [isLoaded, setIsLoaded] = useState(false);
    const loadingBarRef = useRef(null);
    const timeoutDuration = window.innerWidth < 800 ? 1000 : 500;

    useEffect(() => {
        loadingBarRef.current.continuousStart();

        const handleLoad = () => {
            console.log('Loading...');
            loadingBarRef.current.complete();
            setTimeout(() => {
                setIsLoaded(true);
            }, 250); 
        };

        setTimeout(() => {
            handleLoad();
        }, timeoutDuration);

        return () => {
        };
    }, []);

    return (
        <div className="About">
            <LoadingBar color="#1f1f1f" ref={loadingBarRef} />
            <LNavbar visible_var={isLoaded ? 'visible' : 'hidden'}/>
            <Background visible_var={isLoaded ? 'visible' : 'hidden'}/>
        </div>
    );
}

export default About;