import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';

const Navbar = (visible_var) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const visible_str = visible_var.visible_var;
  console.log(visible_str);
  const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
      // add [] around the menu text
      const menu = document.querySelector('.mobile-menu span');
      if (menu) {
        if (isMobileMenuOpen) {
          menu.textContent = 'menu';
        } else {
          menu.textContent = '[menu]';
        }
      }
  };
  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    const navbar_container = document.querySelector('.navbar-container');
    let isFadedOut = false;
    let interval;

    const fadeOut = () => {
      if (navbar) {
        gsap.to(navbar, { opacity: 0, duration: .5 });
        isFadedOut = true;
      }
    };

    const fadeIn = () => {
      if (navbar) {
        gsap.fromTo(navbar, { opacity: 0 }, { opacity: 1, duration: .5 });
        isFadedOut = false;
        clearInterval(interval);
        interval = setInterval(fadeOut, 4000);
      }
    };

    interval = setInterval(fadeOut, 4000);

    if (navbar) {
      navbar.addEventListener('mouseover', fadeIn);
    }

    const links = document.querySelectorAll('.navbar-links div');

    const addBrackets = (event) => {
      const div = event.target;
      const textNode = div.firstChild;
      if (textNode && textNode.nodeType === Node.TEXT_NODE) {
        textNode.textContent = `[${textNode.textContent}]`;
        console.log(textNode.textContent);
        div.style.color = 'grey';
      }
    };
    
    const removeBrackets = (event) => {
      const div = event.target;
      const textNode = div.firstChild;
      if (textNode && textNode.nodeType === Node.TEXT_NODE) {
        textNode.textContent = textNode.textContent.replace(/^\[|\]$/g, '');
        console.log(textNode.textContent);
        div.style.color = 'black';
      }
    };

    if (navbar) {
      links.forEach(link => {
        link.addEventListener('mouseover', addBrackets);
        link.addEventListener('mouseout', removeBrackets);
      });
    }

    const handleResize = () => {
      // Clean up any previous settings
      clearInterval(interval);
    
      if (navbar) {
        gsap.set(navbar, { opacity: 1});
        isFadedOut = false;
        fadeIn();  // Reinitialize the fadeIn behavior
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
      if (navbar) {
        navbar.removeEventListener('mouseover', fadeIn);
        links.forEach(link => {
          link.removeEventListener('mouseover', addBrackets);
          link.removeEventListener('mouseout', removeBrackets);
        });
      }
    };
  }, []);

  return (
<div className="navbar-container">
    {/* First Navbar */}
    <nav className="navbar" style={{opacity:'0'}}>
        <div className="navbar-links">
            <div><Link to="/work">Work</Link></div>
            <div><Link to="/projects">Projects</Link></div>
        </div>
        <div className="navbar-logo">
            <Link to="/">You Gao</Link>
        </div>
        <div className="navbar-links">
            <div><Link to="/about">About Me</Link></div>
            <div><a href="https://blog.yougao.dev/" target="_blank">To Blog</a></div>
        </div>
    </nav>

    {/* Second Navbar */}
    <nav className="mobile-navbar" style={{visibility: visible_str}}>
        <div className="mobile-logo">
            <Link to="/">You Gao</Link>
        </div>
        <div className="mobile-menu-container">
        <button className="mobile-menu" onClick={toggleMobileMenu}>
          <span className="custom-h2" style={{ margin: 0 }}>menu</span>
        </button>
        {isMobileMenuOpen && (
          <div className="mobile-links">
            <div><Link to="/work">Work</Link></div>
            <div><Link to="/projects">Projects</Link></div>
            <div><Link to="/about">About Me</Link></div>
            <div><a href="https://blog.yougao.dev/" target="_blank">To Blog</a></div>
          </div>
        )}
      </div>
      </nav>
</div>
);
};

export default Navbar;